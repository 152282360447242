import { RouteRecordRaw } from "vue-router";

export const settingsRouter: Array<RouteRecordRaw> = [
  {
    path: "settings",
    name: "Settings",
    component: () => import("../views/Settings/Settings.vue"),
    redirect: (to) => {
      if (to.name === "Settings") return { name: "GeneralSettings" };
    },

    meta: {
      breadcrumb: [
        { icon: "pi pi-fw pi-home", to: "/" },
        { label: "Settings" },
      ],
    },
    children: [
      {
        path: "",
        name: "GeneralSettings",
        component: () => import("../views/Settings/views/General.vue"),
      },
      {
        path: "user",
        name: "UserSettings",
        component: () => import("../views/Settings/views/User.vue"),
      },
      {
        path: "appearance",
        name: "AppearanceSettings",
        component: () => import("../views/Settings/views/Appearance.vue"),
      },
      {
        path: "security",
        name: "SecuritySettings",
        component: () => import("../views/Settings/views/Security.vue"),
      },
      {
        path: "notifications",
        name: "NotificationsSettings",
        component: () => import("../views/Settings/views/Notification.vue"),
      },
      {
        path: "other",
        name: "OtherSettings",
        component: () => import("../views/Settings/views/Other.vue"),
      },
      {
        path: ":courseCode",
        name: "CourseRecordSettings",
        component: () => import("../views/Settings/views/CourseRecord.vue"),
      },
    ],
  },
];
