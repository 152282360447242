
import { defineComponent } from "vue";
import { colorScheme } from "@/store";

export default defineComponent({
  name: "AppLogo",
  setup() {
    return { colorScheme };
  },
});
