
import { defineComponent, onBeforeMount, watch } from "vue";
import { colorScheme } from "./store";

export default defineComponent({
  setup() {
    onBeforeMount(() => {
      document.documentElement.setAttribute("data-theme", colorScheme.value);
    });

    watch([colorScheme], () => {
      console.log("updating color Scheme");
      document.documentElement.setAttribute("data-theme", colorScheme.value);
    });
  },
});
