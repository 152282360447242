
import { defineComponent } from "vue";

export default defineComponent({
  name: "MaintenanceMode",
  props: {
    error: {
      type: Object as () => Error,
      required: true,
    },
  },
});
