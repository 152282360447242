import { RouteRecordRaw } from "vue-router";

export const chatRouter: Array<RouteRecordRaw> = [
    {
        path: "/chat",
        name: "Messages",
        component: () => import("../chat/Layout.vue"),
        meta: {
            hideFooter: true,
            breadcrumb: [

            ],
        },
        children: [
            {
                path: "",
                name: "ChatHome",
                component: () => import("../chat/Home.vue"),
            },
            {
                path: "friends",
                name: "Friends",
                component: () => import("../chat/Friends.vue"),

            },
            {
                path: ":serverId",
                name: "ChatRoom",
                component: () => import("../chat/Server.vue"),
            },
            {
                path: ":serverId/channel/:channelId",
                name: "ChannelRoom",
                component: () => import("../chat/Channel.vue"),
            },
            {
                path: "channel/:channelId",
                name: "DirectRoom",
                component: () => import("../chat/Direct.vue"),
            },
        ]
    },
];