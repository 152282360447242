
import { defineComponent } from "vue";

export default defineComponent({
  name: "UserImage",
  props: {
    src: {
      type: String,
    },
    label: {
      type: String,
    },
  },
});
