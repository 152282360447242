import {
  createRouter,
  createWebHistory,
  NavigationGuard,
  RouteRecordRaw,
} from "vue-router";
import { isAuthenticated } from "@/store";
import { settingsRouter } from "./settingsRouter";
import { courseRouter } from "./courseRouter";
import { chatRouter } from "./chatRouter";
import { setTitle } from "@/utils/title";

const MustBeAuthenticated = function (to, from, next) {
  if (!isAuthenticated.value) {
    return next("/login");
  }
  return next();
} as NavigationGuard;

const MustBeUnauthenticated = function (to, from, next) {
  if (isAuthenticated.value) {
    return next("/");
  }
  return next();
} as NavigationGuard;

const innerRoutes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "Home",
    component: () => import("../views/Home.vue"),
    meta: {
      breadcrumb: [{ label: "Dashboard" }, { label: "Student Dashboard" }],
    },
  },
  {
    path: "notifications",
    name: "Notifications",
    component: () => import("../views/Notifications.vue"),
    meta: {
      breadcrumb: [
        { icon: "pi pi-fw pi-home", to: "/" },
        { label: "Notifications" },
      ],
    },
  },
  {
    path: "profile",
    name: "Profile",
    component: () => import("../views/Profile.vue"),
    meta: {
      breadcrumb: [
        { icon: "pi pi-fw pi-home", to: "/" },
        { icon: "pi pi-fw pi-id-card", label: "Profile" },
      ],
    },
  },
  {
    path: "calendar",
    name: "Calendar",
    component: () => import("../views/Calendar.vue"),
    meta: {
      breadcrumb: [
        { icon: "pi pi-fw pi-home", to: "/" },
        { label: "Calendar" },
      ],
    },
  },
  {
    path: "dms",
    name: "Messages",
    component: () => import("../views/Messages.vue"),
    meta: {
      hideFooter: true,
      breadcrumb: [
        { icon: "pi pi-fw pi-home", to: "/" },
        { label: "Messages" },
      ],
    },
  },
  {
    path: "feedback",
    name: "Feedback",
    component: () => import("../views/FeedbackForm.vue"),
  },
  ...settingsRouter,
  ...courseRouter,
  ...chatRouter,
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("../views/NotFound.vue"),
  },
];

const routes: Array<RouteRecordRaw> = [
  {
    path: "/about",
    name: "About",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/login",
    name: "Login",
    beforeEnter: MustBeUnauthenticated,
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/signup",
    name: "Signup",
    beforeEnter: MustBeUnauthenticated,
    component: () =>
      import(/* webpackChunkName: "signup" */ "../views/Signup/Signup.vue"),
  },
  {
    path: "/complete_registration",
    name: "CompleteRegistration",
    beforeEnter: MustBeUnauthenticated,
    component: () =>
      import(
        /* webpackChunkName: "signup" */ "../views/Signup/CompleteRegistration.vue"
      ),
  },
  {
    path: "/cancel_registration",
    name: "CancelRegistration",
    beforeEnter: MustBeUnauthenticated,
    component: () =>
      import(
        /* webpackChunkName: "signup" */ "../views/Signup/CancelRegistration.vue"
      ),
  },
  {
    path: "",
    beforeEnter: MustBeAuthenticated,
    component: () => import(/* webpackChunkName: "mainapp" */ "../App.vue"),
    children: innerRoutes,
  },
  {
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: () => import("../views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.afterEach(() => {
  setTitle();
});

export default router;
