import { Query, Subscription, Notification } from "@/types";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { computed, watchEffect, ref, watch } from "vue";

export const notifications = ref<Notification[]>([]);
export const count = computed(() => notifications.value.filter((n) => n.seenAt == null).length)


export const fetchNotifications = () => {
    const { result, subscribeToMore } = useQuery<Query>(gql`
        query GetNotifications {
            getNotifications {
                id
                message
                createdAt
                seenAt
                scope
            }
        }
    `);

    subscribeToMore<null, Subscription>({
        document: gql`
            subscription OnNotificationCreated {
                notificationCreated {
                    id
                    message
                    createdAt
                    seenAt
                    scope
                }
            }
        `,
        updateQuery: (prev, { subscriptionData }) => {
            console.log(subscriptionData)
            if (!subscriptionData.data) return prev;
            const newNotification = subscriptionData.data.notificationCreated;
            return {
                ...prev,
                getNotifications: [newNotification, ...prev.getNotifications],
            };
        },
    })

    watch([result], () => {
        if (result.value) {
            notifications.value = result.value.getNotifications;
        }
    });

};
