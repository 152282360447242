import { gql } from "graphql-tag";

export const fetchCourseRecords = gql`
  query GetCourseRecords {
    getCourseRecords {
      id
      courseCode
      title
      color
      order
      valuables {
        id
        date
        grade {
          mark
          total
        }
        distribution {
          ... on NormalDistribution {
            sd
            mean
          }
        }
        title
        weight
      }
    }
  }
`;

export const fetchCourseRecord = gql`
  query GetCourseRecord($courseCode: String!) {
    getCourseRecord(courseCode: $courseCode) {
      id
      courseCode
      title
      color
      valuables {
        id
        date
        distribution {
          ... on NormalDistribution {
            sd
            mean
          }
        }
        grade {
          mark
          total
        }
        title
        weight
      }
    }
  }
`;

export const fetchRooms = gql`
  query GetRooms {
    getRooms {
      __typename
      id
      roomName
      ... on CourseRoom {
        courseRecord {
          id
          color
        }
      }
    }
  }
`;