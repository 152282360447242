
import { defineComponent } from "vue";
import { colorScheme } from "@/store";

export default defineComponent({
  name: "AppName",
  setup() {
    return { colorScheme };
  },
});
