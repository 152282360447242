import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-53987768"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-image" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 1,
  class: "label"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.src)
      ? (_openBlock(), _createElementBlock("img", {
          key: 0,
          src: _ctx.src
        }, null, 8, _hoisted_2))
      : (_ctx.label)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.label[0]), 1))
        : _createCommentVNode("", true)
  ]))
}