import { RouteRecordRaw } from "vue-router";
import { state } from "@/store";
export const courseRouter: Array<RouteRecordRaw> = [{
  path: "course/:courseCode",
  name: "CourseRecord",
  component: () => import("../views/CourseRecord.vue"),
  meta: {
    breadcrumb: [{ icon: "pi pi-fw pi-home", to: "/" }, { label: "Courses" }],
  },
},
{
  path: "course",
  children: [
    {
      path: "add",
      redirect: () => {
        return { name: "SearchCourse" };
      },
    },
    {
      path: "add/:courseCode",
      name: "AddCourse",
      component: () => import("../views/Course/Course.vue"),
      beforeEnter: (to, from, next) => {
        const courseCode = to.params.courseCode;
        if (
          state.courseRecords.find((record) => record.courseCode === courseCode)
        )
          return next({
            name: "CourseRecord",
            params: { courseCode: courseCode },
          });
        return next();
      },
      children: [
        {
          path: "",
          name: "SelectTemplate",
          component: () => import("../views/Course/SelectTemplate.vue"),
        },
        {
          path: "create",
          name: "CreateTemplate",
          component: () => import("../views/Course/CreateTemplate.vue"),
          meta: {
            breadcrumb: [
              { icon: "pi pi-fw pi-home", to: "/" },
              { label: "Courses", to: { name: "SearchCourse" } },
              { label: "Create New Template" },
            ],
          },
        },
        {
          path: ":templateId",
          name: "ExistingTemplate",
          component: () => import("../views/Course/ExistingTemplate.vue"),
        },
      ],
    },
    {
      path: "search",
      name: "SearchCourse",
      component: () => import("../views/Course/Search.vue"),
      meta: {
        breadcrumb: [
          { icon: "pi pi-fw pi-home", to: "/" },
          { label: "Courses" },
          { label: "Search" },
        ],
      },
    },
    {
      path: "create",
      name: "CreateCourse",
      component: () => import("../views/Course/Create.vue"),
    },
  ],
}];
