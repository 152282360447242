import { createClient } from 'graphql-ws';
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloLink,
  split,
} from "@apollo/client/core";
import { onError } from "@apollo/client/link/error";
import { provideApolloClient } from "@vue/apollo-composable";
import { setContext } from "apollo-link-context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import router from "./router";
import { state } from "./store";

const getAddress = (address?: string, protocol?: string) => {
  const url = new URL(
    address || document.location.href
  );
  if (protocol) {
    url.protocol = protocol;
  }
  return url.href;
};

// HTTP connection to the API
const httpLink = createHttpLink({
  // You should use an absolute URL here
  uri: getAddress(process.env.VUE_APP_GRAPHQL_URL),
});

const wsLink = new GraphQLWsLink(createClient({
  url: getAddress(process.env.VUE_APP_WS_URL),
  lazy: true,
  connectionParams: () => {
    const token = localStorage.getItem("user-token");
    return {
      Authorization: "Bearer " + token,
    };
  },
}));

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      if (err.extensions.code === "UNAUTHENTICATED") {
        apolloClient.clearStore();
        localStorage.removeItem("user-token");
        router.go(0);
      }
      console.error(err);

    }
    if (networkError) {
      state.error = networkError;
      console.error(`[Network error]`, networkError);
    }
    return;
  }
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from ApplicationSettings if it exists
  const token = localStorage.getItem("user-token");
  // return the headers to the context so HTTP link can read them
  if (!token) {
    return headers;
  }
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : null,
    },
  };
}) as unknown as ApolloLink;

// Cache implementation
const cache = new InMemoryCache();

// Create the apollo client
const apolloClient = new ApolloClient({
  link: authLink.concat(errorLink).concat(link),
  cache,
  name: 'web',
  version: '0.2.4'
});

provideApolloClient(apolloClient);

export default apolloClient;
