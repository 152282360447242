import { fetchCourseRecords as courseRecordsQueryDocument } from "../graphql/query";
import { CourseRecord, Maybe, Query, User } from "./../types";
import { computed, reactive, ref, Ref, watch, watchEffect } from "vue";
import apolloClient from "../apollo";
import { useQuery } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { fetchNotifications } from "./notifications";

export type ColorSchemePreference = "light" | "dark" | "system";
export type MenuMode = "sidebar" | "horizontal" | "slim" | "overlay" | "static";

interface Preferences {
  preferredColorScheme: ColorSchemePreference;
  menuMode: MenuMode;
}

function getFromLocalStorage<T>(
  key: string,
  defaultValue: string = null
): T | null {
  const existingValue = localStorage.getItem(key);
  if (!existingValue && defaultValue) {
    localStorage.setItem(key, defaultValue);
    return defaultValue as T;
  } else if (!existingValue) return null;
  return existingValue as T;
}

export const state = reactive({
  token: localStorage.getItem("user-token"),
  user: null as Maybe<User>,
  courseRecords: [] as Array<CourseRecord>,
  loadingRefs: [] as Array<Ref<boolean>>,
  error: null as Error,
});

export const preferences = reactive<Preferences>({
  preferredColorScheme: getFromLocalStorage("color-scheme", "system"),
  menuMode: getFromLocalStorage("menu-mode", "sidebar"),
});


watch([preferences], () => {
  localStorage.setItem("color-scheme", preferences.preferredColorScheme);
  localStorage.setItem("menu-mode", preferences.menuMode);
});


export const isAuthenticated = computed(() => {
  return !!state.token;
});

export const loading = computed(() => {
  return state.loadingRefs.some((ref) => ref.value);
});

const darkMode = ref(
  window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches
);

window
  .matchMedia("(prefers-color-scheme: dark)")
  .addEventListener("change", (event) => {
    darkMode.value = event.matches;
  });

export const colorScheme = computed(() => {
  if (preferences.preferredColorScheme !== "system")
    return preferences.preferredColorScheme;
  else if (darkMode.value) {
    return "dark";
  } else {
    return "light";
  }
});

function resetStore() {
  state.token = null;
  state.user = null as User;
  state.courseRecords = [];
  state.error = null;
}

export function logout() {
  apolloClient.clearStore();
  localStorage.removeItem("user-token");
  resetStore();
}

const fetchCourseRecords = async () => {
  const { result, loading, error } = useQuery<Query>(
    courseRecordsQueryDocument
  );
  state.loadingRefs.push(loading);

  watchEffect(() => {
    if (result.value?.getCourseRecords) {
      state.courseRecords = result.value.getCourseRecords;
    }
  });

  // onResult(({ data }) => {
  //   if (data?.getCourseRecords) {
  //     state.courseRecords = data.getCourseRecords;
  //   }
  // });

  return { loading, error };
};
const fetchAuthUser = async () => {

  const { result, loading, error } = useQuery<Query>(
    gql`
      query GetAuthUser {
        getAuthUser {
          username
          id
          name
          email
          profilePic
          school {
            imageURL
            name
          }
          type
        }
      }
    `,
    { fetchPolicy: "network-only" }
  );
  state.loadingRefs.push(loading);


  watchEffect(() => {
    if (result.value?.getAuthUser) {
      state.user = result.value.getAuthUser;
    }
  });

  return { loading, error };
};

export const initStore = () => {
  fetchAuthUser();
  fetchCourseRecords();
  fetchNotifications();
};
